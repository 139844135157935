<script setup lang="ts">
import { computed, type PropType, type Ref, ref, watch, onMounted } from 'vue';
import { useMetaStore } from '~/stores/meta.store';
import { useRouter, useRoute } from 'vue-router';
import { type Project } from '~/types/project.type';
import { type Pagination } from '~/types/pagination.type';
import { type Country } from '~/types/country.type';
import { type MetaAttributes } from '~/types/meta-attributes.type';
import ProjectCard from './partials/project-card.vue';
import ProjectsFilter from './partials/filters.vue';
import ProjectsSort from './partials/sort.vue';
import ContactModal from './partials/contact-modal.vue';
import { useProjectsFiltersStore } from '~/stores/projects-filters.store';
import { useProjectsStore } from '~/stores/projects.store';

const props = defineProps<{
  data: {
    data: {
      projects: Project[];
      country: Country;
      pagination: Pagination;
    };
    meta: MetaAttributes;
  };
}>();

const router = useRouter();
const route = useRoute();
const metaStore = useMetaStore();
const filtersStore = useProjectsFiltersStore();
const projectsStore = useProjectsStore();
const scrollPosition = ref(0);
const isAtBottom = ref(false);
const isLoading = ref(false);
const endOfPage = ref(false);

const isEmailFormOpen = ref(false);
const isFiltersOpen = ref(false);
const page: Ref<Number> = ref(1);
const pageData: Ref<{
	projects: Project[];
	country: Country;
	pagination: Pagination
}> = computed(() => props.data.data);

const checkScroll = () => {
	scrollPosition.value = window.scrollY;
	isAtBottom.value = window.innerHeight + window.scrollY >= document.body.offsetHeight;
}

onMounted(async () => {
	window.addEventListener('scroll', checkScroll);
})
const country_id = pageData.value.country?.country_id || pageData.value.country._id

let countries = await projectsStore.fetchCountriesHasProjects();

watch(page, async (newPage) => {
	// Update query
	await router.push({
		query: { ...route.query, page: newPage.toString() },
	});
});

watch(() => route.query, async () => {
	if (isLoading.value) {
		return;
	}
	if (Number(route.query?.page) == 1) {
		page.value = 1
	}

	isLoading.value = true;
	// Update query
	await router.push({
		query: { ...route.query },
	});

	// Fetch and update new page data
	const projects = await projectsStore.fetchProjects(route);

	if (projects?.data?.projects.length) {
		endOfPage.value = false;
		pageData.value.projects = projects.data.projects;
	}
	isLoading.value = false;
});

watch(isAtBottom, async () => {
	if (isLoading.value || endOfPage.value) {
		return;
	}
	isLoading.value = true;

	await router.push({
		query: { ...route.query, page: ++page.value },
	});


	// Fetch and update new page data
	const projects = await projectsStore.fetchProjects(route);
	if (projects?.data?.projects?.length) {
		pageData.value.projects.push(...projects.data.projects);
	} else {
		endOfPage.value = true;
	}
	isLoading.value = false;
});

const showEmailForm = () => {
	isEmailFormOpen.value = true;
}
metaStore.setMeta(props.data.meta);
</script>

<template>
	<LayoutAppHead></LayoutAppHead>

	<NuxtLayout name="mobile-base">
		<template #mobile-header>
			<MobileHeader title="Projects" :sub-title="pageData.country.name">

				<template #header-left>
					<div class="flex items-center gap-6">
						<ProjectsFilter :country_id="country_id"></ProjectsFilter>
						<ProjectsSort></ProjectsSort>
					</div>
				</template>

				<template #header-right>
					<div class="flex items-center gap-6">
						<mobile-share-button></mobile-share-button>
					</div>
				</template>

			</MobileHeader>
		</template>

		<div>
			<!-- Countries Top Bar -->
			<div class="sticky top-20 z-50 flex bg-white items-center shadow">
				<div class="flex space-x-4 border-b border-gray-200 w-full text-center">
					<NuxtLink v-for="country in countries" :key="country.slug" class="grow py-2 px-4 font-medium"
						:class="route.params.location === country.slug ? 'text-indigo-900 border-b-2 border-indigo-900' : 'text-gray-500'"
						:to="localePath({ name: 'projects.index', params: { location: country.slug } })">
						{{ country.name }}
					</NuxtLink>
				</div>
			</div>

			<template v-if="pageData.projects && pageData.projects?.length">
				<div class="flex flex-col item-center px-4 mt-4">
					<div v-for="project in pageData.projects" :key="project._id">
						<ProjectCard :project="project" :country="pageData.country" :showEmailForm="showEmailForm" />
					</div>
				</div>
			</template>
			<div v-else class="text-center text-2xl py-12">
				{{ $t('client.no_projects') }}
			</div>
			<div class="absolute -bottom-5 left-1/2 -translate-x-1/2">
				<span class="loader" v-if="isLoading"></span>
			</div>

			<ContactModal v-model="isEmailFormOpen" />
		</div>
	</NuxtLayout>
</template>
